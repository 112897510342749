import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { SharedService } from "../shared.service";
import { LayoutUtilsService } from "../../../core/_base/crud";
import { TranslateService } from "@ngx-translate/core";
import { CustomerTypes } from "../enums/customer/customerTypes";
import { EntitySubCustomersType, EntitySubCustomersTypeTranslationMapper } from "./entity-sub-customers-type.enum";
import { debounceTime, startWith, switchMap } from "rxjs/operators";
import { constructErrorDetailsMessage } from "../../../core/_base/crud/utils/error-utils";
import { CustomerEditComponent } from "../../pages/customers/customer-edit.component";

@Component({
  selector: "kt-attach-sub-customers",
  templateUrl: "./attach-sub-customers.component.html",
  styleUrls: ["./attach-sub-customers.component.scss"],
})
export class AttachSubCustomersComponent implements OnInit, OnDestroy {
  viewLoading: boolean = false;
  customers: any;
  existingCustomersIds: any;
  subCustomerTypes: EntitySubCustomersType[] = Object.values(EntitySubCustomersType);
  CustomerTypes = CustomerTypes;
  form: FormGroup;
  subCustomers: FormArray;
  newCustomers: any[] = [];
  subscriptions: Subscription[] = [];
  subCustomerTypesTranslation = EntitySubCustomersTypeTranslationMapper;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private layoutUtilService: LayoutUtilsService,
    private readonly translate: TranslateService,
    public dialogRef: MatDialogRef<AttachSubCustomersComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.customers = this.data.customers;
    this.existingCustomersIds = this.customers
      .filter((contractCustomer) => contractCustomer.is_active === true)
      .map((customer) => customer.id);
    this.form = this.fb.group({
      subCustomers: this.fb.array([]),
    });

    this.subCustomers = this.form.get("subCustomers") as FormArray;
    this.initializeSubCustomers();
    this.trackCustomerChanges();
  }

  initializeSubCustomers(): void {
    if (this.data.sub_customers?.length === 0) {
      this.addCustomer();
      return;
    }
    this.data.sub_customers?.forEach((subCustomer) => {
      this.subCustomers.push(this.createSubCustomerForm(subCustomer));
    });
  }

  createSubCustomerForm(subCustomer: any): FormGroup {
    return this.fb.group({
      type: [subCustomer?.type ?? "", Validators.required],
      customer: [subCustomer?.customer ?? null, Validators.required],
    });
  }

  loadCustomers(query: string = ""): void {
    const params = { q: query, order_column: "name", order_dir: "asc" };

    this.sharedService.getCustomersList(params).subscribe((res) => {
      this.newCustomers = res?.data ?? [];
    });
  }

  trackCustomerChanges(): void {
    this.trackFormArrayChanges(this.subCustomers);
  }

  trackFormArrayChanges(formArray: FormArray): void {
    formArray?.controls.forEach((control: FormGroup) => {
      const customerControl = control.get("customer");
      if (customerControl) {
        const sub = customerControl.valueChanges
          .pipe(
            startWith(""),
            debounceTime(250),
            switchMap((value) => {
              this.loadCustomers(value);
              return [];
            })
          )
          .subscribe();
        this.subscriptions.push(sub);
      }
    });
  }

  addCustomer(): void {
    const customerForm = this.fb.group({
      type: ["", Validators.required],
      customer: [null, Validators.required],
    });

    if (this.subCustomers.invalid) {
      return;
    }
    this.subCustomers.push(customerForm);
    this.trackCustomerChanges();
  }

  removeCustomer(index: number): void {
    this.subCustomers.removeAt(index);
  }

  displayCustomerName(customer: any): string {
    return customer ? customer.name : "";
  }

  getFilteredCustomers(customers: any[]): any[] {
    return customers?.filter((customer) => !this.existingCustomersIds.includes(customer.id));
  }

  getFilteredTypes(
    types: EntitySubCustomersType[],
    customers: FormArray,
    currentIndex: number
  ): EntitySubCustomersType[] {
    const selectedTypesInOtherCustomers = customers.controls
      .filter((_, index) => index !== currentIndex)
      .map((control) => control.get("type")?.value);

    return types.filter((type) => !selectedTypesInOtherCustomers.includes(type));
  }

  isEmptySubCustomers() {
    return !this.subCustomers?.length;
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.viewLoading = true;

      const payload =
        this.subCustomers?.controls.map((control: FormGroup) => {
          if (!control.get("customer")?.value?.id) {
            control.get("customer").setErrors({ required: true });
          }
          return { customer_id: control.get("customer")?.value?.id, type: control.get("type")?.value };
        }) ?? [];

      if (this.form.invalid) {
        this.viewLoading = false;
        return;
      }

      this.sharedService.attachCustomerToEntity(payload, this.data.entity_id, this.data.entity_type).subscribe({
        next: (res) => {
          this.viewLoading = false;
          this.layoutUtilService.showActionNotification(this.translate.instant(res?.message));
          this.dialogRef.close(true);
        },
        error: (err) => {
          this.viewLoading = false;
          this.layoutUtilService.showActionNotification(constructErrorDetailsMessage(err));
        },
      });
    }
  }

  onAddNewCustomer() {
    this.dialog.open(CustomerEditComponent, {
      width: "900px",
      maxHeight: "90vh",
      data: { isCancelEnabled: true, entityType: "offer" },
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
