<div class="kt-portlet">
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-progress">
      <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">{{ "SUB_CUSTOMERS.TITLE" | translate }}</h3>
    </div>
  </div>

  <div class="kt-form">
    <div class="kt-portlet__body">
      <form [formGroup]="form" autocomplete="off">
        <div formArrayName="subCustomers">
          <div class="row">
            <div class="col-md-12 mb-4">
              <div *ngFor="let subCustomer of subCustomers.controls; let i = index" [formGroupName]="i">
                <div class="row">
                  <div class="col-md-5">
                    <label>{{ "SUB_CUSTOMERS.SUB_CUSTOMER" | translate }}</label>
                    <mat-form-field class="mat-form-field-fluid">
                      <input matInput formControlName="customer" [matAutocomplete]="auto" />
                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCustomerName">
                        <mat-option *ngFor="let customer of getFilteredCustomers(newCustomers)" [value]="customer">
                          {{ customer.name }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error *ngIf="subCustomer.get('customer')?.hasError('required')">
                        {{ "SUB_CUSTOMERS.NAME_REQUIRED" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-5">
                    <label>{{ "SUB_CUSTOMERS.TYPE" | translate }}</label>
                    <mat-form-field class="mat-form-field-fluid">
                      <mat-select formControlName="type">
                        <mat-option
                          *ngFor="let type of getFilteredTypes(subCustomerTypes, subCustomers, i)"
                          [value]="type"
                        >
                          {{ subCustomerTypesTranslation.get(type) | translate }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="subCustomer.get('type')?.hasError('required')">
                        {{ "SUB_CUSTOMERS.TYPE_REQUIRED" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-2">
                    <button
                      type="button"
                      class="m-4"
                      mat-mini-fab
                      color="warn"
                      (click)="removeCustomer(i, CustomerTypes.PRIVATE)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
              <a href="javascript:;" class="kt-font-primary d-block" (click)="onAddNewCustomer()">
                <span>
                  <i class="fas fa-plus" style="font-size: 10px"></i>
                  {{ "CUSTOMERS.VIEW.NEW_BUTTON_TOOLTIP" | translate }}
                </span>
              </a>
            </div>
            <button
              type="button"
              mat-mini-fab
              color="accent"
              [disabled]="!this.subCustomers.valid || subCustomers.length === subCustomerTypes.length"
              (click)="addCustomer()"
            >
              <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>
        <div class="text-right">
          <button
            type="button"
            mat-button
            color="primary"
            (click)="onSubmit()"
            [disabled]="!form.valid || isEmptySubCustomers()"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
