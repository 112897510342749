<div class="kt-portlet">
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-progress">
      <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    </div>
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        {{ (updateVehicle ? "VEHICLE.VIEW.UPDATE_VEHICLE" : "VEHICLE.VIEW.IMPORT") | translate }}
      </h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>
  <div class="kt-form">
    <div class="kt-portlet__body">
      <ng-container *ngIf="updateVehicle && !existingVinNumber">
        <kt-alert type="warn" [showCloseButton]="false">
          {{ "VEHICLE.VIEW.VIN_NUMBER_MISSING" | translate }}
        </kt-alert>
      </ng-container>
      <div class="kt-form__group">
        <div>
          <label class="mat-input-label">{{ "VEHICLE.ADD.FIELDS.VIN_NUMBER.PLACEHOLDER" | translate }}</label>
          <div class="d-flex align-items-center">
            <mat-form-field class="example-full-width searchable">
              <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
              <input
                type="text"
                placeholder="{{ 'VEHICLE.ADD.FIELDS.VIN_NUMBER.PLACEHOLDER' | translate }}"
                matInput
                [formControl]="vinNumberControl"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="select($event)">
                <mat-option *ngIf="importedVehicle" [value]="importedVehicle">
                  {{ importedVehicle.vin_number }}
                </mat-option>
              </mat-autocomplete>
              <mat-error>
                <span
                  *ngIf="vinNumberControl.hasError('required')"
                  [innerHTML]="'VEHICLE.ADD.FIELDS.VIN_NUMBER.ERROR_MSG' | translate"
                ></span>
                <span
                  *ngIf="vinNumberControl.hasError('pattern')"
                  [innerHTML]="'VEHICLE.ADD.FIELDS.VIN_NUMBER.VALID_LENGTH' | translate"
                ></span>
              </mat-error>
            </mat-form-field>
            <div style="padding-bottom: 1.25em">
              <button
                (click)="search()"
                class="btn btn-primary mx-4"
                color="primary"
                mat-raised-button
                [disabled]="vinNumberControl.invalid || loading"
              >
                {{ "GENERAL.SEARCH" | translate }}
                <span class="kt-portlet__head-icon">
                  <i class="la la-search"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div *ngIf="importedVehicle">
          <div class="summary-table-wrapper">
            <h5 class="summary-title">
              {{ "CONTRACTS.ADD.TABS.SUMMARY.ASSET" | translate }}
            </h5>
            <table class="table table-bordered summary-table">
              <tbody>
                <tr>
                  <td>
                    {{ "VEHICLE.ADD.FIELDS.MAKE.PLACEHOLDER" | translate }}
                  </td>
                  <td>
                    {{ importedVehicle.make?.name ?? "-" }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ "VEHICLE.VIEW.TABLE_COLUMNS.MODEL" | translate }}
                  </td>
                  <td>
                    {{ importedVehicle.model?.model ?? "-" }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ "VEHICLE.ADD.FIELDS.VEHICLE_TYPE.PLACEHOLDER" | translate }}
                  </td>
                  <td>
                    {{ importedVehicle.type?.name ?? "-" }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ "VEHICLE.ADD.FIELDS.FIRST_REGISTERATION_DATE.PLACEHOLDER" | translate }}
                  </td>
                  <td>
                    {{ importedVehicle.first_registration_date | dmyDate }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ "VEHICLE.ADD.FIELDS.NEXT_INSPECTION.PLACEHOLDER" | translate }}
                  </td>
                  <td>
                    {{ importedVehicle.next_inspection ?? "-" }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ "VEHICLE.ADD.FIELDS.VIN_NUMBER.PLACEHOLDER" | translate }}
                  </td>
                  <td>
                    {{ importedVehicle.vin_number ?? "-" }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ "VEHICLE.ADD.FIELDS.CO2_PER_KM.PLACEHOLDER" | translate }}
                  </td>
                  <td>
                    {{ importedVehicle.co2_per_km ?? "-" }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ "VEHICLE.ADD.FIELDS.FUEL_TYPE.PLACEHOLDER" | translate }}
                  </td>
                  <td>
                    {{ importedVehicle.fuel_type ?? "-" }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ "VEHICLE.ADD.FIELDS.TRIM.PLACEHOLDER" | translate }}
                  </td>
                  <td>
                    {{ importedVehicle.trim ?? "-" }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ "VEHICLE.ADD.FIELDS.YEAR.PLACEHOLDER" | translate }}
                  </td>
                  <td>
                    {{ importedVehicle.year ?? "-" }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ "VEHICLE.ADD.FIELDS.FIRST_REGISTERATION_DATE.PLACEHOLDER" | translate }}
                  </td>
                  <td>
                    {{ importedVehicle.first_registration_date | dmyDate }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ "VEHICLE.ADD.FIELDS.HORSE_POWER.PLACEHOLDER" | translate }}
                  </td>
                  <td>
                    {{ importedVehicle.horse_power ?? "-" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          *ngIf="isSearch && !importedVehicle"
          class="d-flex justify-content-center align-items-center text-center fw-bold"
        >
          {{ "VEHICLE.VIEW.NO_VEHICLE_FOUND" | translate }}
        </div>
      </div>
    </div>
    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
      <div class="kt-form__actions kt-form__actions--sm">
        <button mat-button (click)="close()">
          {{ "GENERAL.CANCEL" | translate }}
        </button>
        <button mat-button (click)="submit()" color="primary" [disabled]="loading || !importedVehicle">
          {{ (updateVehicle ? "VEHICLE.VIEW.UPDATE" : "GENERAL.IMPORT") | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
