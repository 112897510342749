import { Component, Inject } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { vehicleVinNumberPattern } from "../../../core/_base/crud/utils/form-utils";
import { VehiclesService } from "../../pages/vehicles-management/vehicles";
import { finalize } from "rxjs/operators";
import { VehicleModel } from "../vehicle-form/vehicle.model";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { LayoutUtilsService, MessageType } from "../../../core/_base/crud";
import { constructErrorDetailsMessage } from "../../../core/_base/crud/utils/error-utils";
import { ConfirmationModalComponent } from "../components/confirmation-modal/confirmation-modal.component";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";

@Component({
  selector: "kt-import-external-vehicle-dialog",
  templateUrl: "./import-external-vehicle-dialog.component.html",
})
export class ImportExternalVehicleDialogComponent {
  loading = false;
  existingVehicle = this.data?.vehicle;
  existingVinNumber = this.existingVehicle?.vin_number;
  updateVehicle = this.data?.updateVehicle ?? false;
  vinNumberControl = this.formBuilder.control(this.existingVinNumber ?? "", [
    Validators.pattern(vehicleVinNumberPattern),
    Validators.required,
  ]);
  importedVehicle: VehicleModel | undefined;
  isSearch = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private vehiclesService: VehiclesService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<void>,
    private layoutUtilsService: LayoutUtilsService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {
    if (this.updateVehicle) {
      this.vinNumberControl.disable();
    }
  }

  search() {
    this.loading = true;
    this.vehiclesService
      .getVehicleFromDBI(this.vinNumberControl.value)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (res) => {
          this.isSearch = true;
          if (res.data?.vin_number) {
            this.importedVehicle = res.data;
          } else {
            this.importedVehicle = undefined;
          }
        },
        error: (error) => {
          this.layoutUtilsService.showActionNotification(constructErrorDetailsMessage(error));
        },
      });
  }

  select(event: MatAutocompleteSelectedEvent) {
    this.importedVehicle = event.option.value;
  }

  close() {
    this.dialogRef.close();
  }

  submit(): void {
    if (this.updateVehicle) {
      this.update();
    } else {
      this.import();
    }
  }

  import() {
    this.dialogRef.close(this.importedVehicle);
  }

  update() {
    this.showConfirmUpdateDialog(this.importedVehicle);
  }

  mapVehicleDetails(vehicleDetails: any) {
    vehicleDetails = Object.fromEntries(Object.entries(vehicleDetails).filter(([key, value]) => value !== null));
    return {
      ...vehicleDetails,
      vehicle_make: vehicleDetails.make,
      vehicle_model: vehicleDetails.model,
      year: +vehicleDetails.year,
      mileage_car: vehicleDetails.latest_mileage,
      first_registration_date: vehicleDetails.first_registration_date
        ? moment(vehicleDetails.first_registration_date).format("DD-MM-YYYY")
        : null,
      next_inspection: vehicleDetails.next_inspection
        ? moment(vehicleDetails.next_inspection).format("DD-MM-YYYY")
        : null,
    };
  }

  showConfirmUpdateDialog(importedVehicle: any) {
    const payload = this.mapVehicleDetails(importedVehicle);
    delete payload["vehicle_condition_id"];
    payload["vehicle_valuation"] = 0;
    payload["_method"] = "PUT";
    const confirmDialog = this.dialog.open(ConfirmationModalComponent, {
      maxWidth: "400px",
      data: {
        message: this.translate.instant("VEHICLE.VIEW.CONFIRM_UPDATE"),
        title: "Alert",
      },
    });

    confirmDialog.afterClosed().subscribe((confirmRes) => {
      if (confirmRes) {
        this.loading = true;
        this.vehiclesService.updateProduct(payload, this.existingVehicle.id).subscribe(
          (res) => {
            if (res.data) {
              this.layoutUtilsService.showActionNotification(res.message, MessageType.Create, 1000, true);
            }
            this.dialogRef.close(res.data);
            this.loading = false;
          },
          (err) => {
            this.loading = false;
            this.layoutUtilsService.showActionNotification(
              err.message ?? this.translate.instant("GENERAL.SERVER_ERRORS_MSG"),
              MessageType.Delete,
              1000,
              true
            );
          }
        );
      }
    });
  }
}
