// Angular
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxPermissionsService } from "ngx-permissions";
// RxJS
import { BehaviorSubject, Observable, of, Subscription } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";
// CRUD
import { LayoutUtilsService, MessageType, TypesUtilsService } from "../../../core/_base/crud";

import { SubheaderService } from "../../../core/_base/layout";
import { SharedService } from "../shared.service";
import { VehicleModel } from "./vehicle.model";
import { TranslateService } from "@ngx-translate/core";
import * as _moment from "moment";
import { default as _rollupMoment, Moment } from "moment";
import { MatDatepicker } from "@angular/material/datepicker";
import { FuelTypes } from "../enums/vehicle/fuelTypes";
import { DropzoneComponent } from "ngx-dropzone-wrapper";
import { EntityType } from "../enums/entity/entityType";
import { IResponse } from "../../../../app/core/_base/crud/interfaces/response-interface";
import { BaseSettingsModel } from "../../pages/settings/base-settings.model";
import { IRequiredFieldsSettings } from "../../pages/settings/components/required-fields-settings-dialog/required-fields-settings.interface";
import { Store } from "@ngrx/store";
import { AppState } from "../../../../app/core/reducers";
import { useVehicleFieldsSettings } from "../../../../app/core/_base/crud/utils/form-utils";
import { selectSettings } from "../../../../app/core/auth/_selectors/setting.selectors";
import { ImportExternalVehicleDialogComponent } from "../import-external-vehicle-dialog/import-external-vehicle-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Route, Router } from "@angular/router";

const moment = _rollupMoment || _moment;

@Component({
  selector: "kt-vehicle-form",
  templateUrl: "./vehicle-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .add-vehicle-view .mat-tab-header {
        display: none;
      }
    `,
  ],
})
export class VehicleFormComponent implements OnInit, OnDestroy {
  // Public properties
  @Input() id: any;
  @Input() withSubmit: boolean = true;
  @Input() withBreadcrumb: boolean = false;
  @Input() vehicle_valuation: boolean = true;
  @Input() entityType: EntityType;
  @Input() isVehicleFromService: boolean = false;
  @Output() vehicleSubmitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() isVehicleFromServiceChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild("mileagePhoto") mileagePhotoComponent: DropzoneComponent;
  forVehicleValuation: boolean = false;
  dropZoneConfig = {
    acceptedFiles: ".png, .jpg, .jpeg, .gif, .pdf, .heic",
    maxFilesize: 20,
    maxFiles: 1,
  };
  product: VehicleModel;
  FuelTypesEnum = FuelTypes;
  loadingSubject = new BehaviorSubject<boolean>(true);
  loading$: Observable<boolean>;
  vehicleTypes: any[] = [];
  vehicleConditions: any[];
  fuelTypes: any[];
  fuelMeasurements: any[];
  isReadonly: boolean = false;
  productForm: FormGroup;
  permissions: any = {}; // loggedin user permissions
  hasFormErrors: boolean = false;
  errorMsgs: any = {}; //Back-end errors
  // Private password
  private componentSubscriptions: Subscription[] = [];
  // sticky portlet header margin
  private headerMargin: number;
  filteredMakes: Observable<any[]>;
  filteredModels: Observable<any[]>;
  formSettings: BaseSettingsModel<IRequiredFieldsSettings>;
  isMileagePhotoValid: boolean = true;
  isMileagePhotoRequired: boolean = false;
  settings$: Observable<IResponse<BaseSettingsModel<IRequiredFieldsSettings>>>;
  importedVehicle: any;

  constructor(
    private productFB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private typesUtilsService: TypesUtilsService,
    private productsService: SharedService,
    private cdr: ChangeDetectorRef,
    private subheaderService: SubheaderService,
    private permissionsService: NgxPermissionsService,
    private translate: TranslateService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.loading$ = this.loadingSubject.asObservable();
    this.loadingSubject.next(true);

    this.permissionsService.permissions$.subscribe((res) => {
      this.permissions = res;
      this.isReadonly = this.id && !res.update_vehicle ? true : false;
      if (!this.id) {
        const newProduct = new VehicleModel();
        newProduct.clear();
        this.loadProduct(newProduct);
      }
    });

    // sticky portlet header
    window.onload = () => {
      const style = getComputedStyle(document.getElementById("kt_header"));
      this.headerMargin = parseInt(style.height, 0);
    };
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.productForm.get("year").value || moment();
    ctrlValue.year(normalizedYear.year());
    this.productForm.get("year").setValue(ctrlValue);
    datepicker.close();
  }

  loadLookups() {
    this.productForm?.markAsDirty();
    const fuelTypesSubscription = this.productsService.getLookups("fuel_types").subscribe((res) => {
      this.fuelTypes = res.data;
    });

    const fuelMeasurementsSubscription = this.productsService.getLookups("fuel_measurement").subscribe((res) => {
      this.fuelMeasurements = res.data;
    });
    const vehicleConditionsSubscription = this.productsService.getVehicleConditions().subscribe((res) => {
      this.vehicleConditions = res.data;
    });
    const vehicleTypesSubscription = this.productsService.getVehicleTypes().subscribe((res) => {
      this.vehicleTypes = res.data;
    });
    this.componentSubscriptions.push(fuelTypesSubscription);
    this.componentSubscriptions.push(fuelMeasurementsSubscription);
    this.componentSubscriptions.push(vehicleConditionsSubscription);
    this.componentSubscriptions.push(vehicleTypesSubscription);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadLookups();
    if (changes["id"] && changes["id"].currentValue) {
      this.id = changes["id"].currentValue;
      if (this.isVehicleFromService) {
        this.isVehicleFromServiceChange.emit(false);
      } else {
        this.loadProductFromService(changes["id"].currentValue);
      }
    }
    if (changes["vehicle_valuation"] && changes["vehicle_valuation"].currentValue) {
      this.vehicle_valuation = changes["vehicle_valuation"].currentValue;
      this.productForm.markAsDirty();
    }
  }

  loadProduct(_product) {
    this.product = _product;
    if (this.withBreadcrumb) {
      this.initBreadcrumb();
    }
    this.initProduct();
    this.cdr.markForCheck();
  }

  loadProductFromService(productId: number) {
    const routeState = history.state;
    this.importedVehicle = routeState.importedVehicle;
    if (this.importedVehicle) {
      this.loadProduct(this.mapVehicleDetails(this.importedVehicle));
    } else {
      const getProductByIdSubscription = this.productsService.getVehicleById(productId).subscribe((res) => {
        this.loadProduct(this.mapVehicleDetails(res.data));
      });
      this.componentSubscriptions.push(getProductByIdSubscription);
    }
  }

  mapVehicleDetails(vehicleDetails: any) {
    return {
      ...vehicleDetails,
      vehicle_make: vehicleDetails.make,
      vehicle_model: vehicleDetails.model,
      year: +vehicleDetails.year,
      mileage_car: vehicleDetails.latest_mileage,
      first_registration_date: vehicleDetails.first_registration_date
        ? moment(vehicleDetails.first_registration_date)
        : null,
      next_inspection: vehicleDetails.next_inspection ? moment(vehicleDetails.next_inspection) : null,
    };
  }

  initBreadcrumb() {
    const TITLE = this.translate.instant("VEHICLE.VIEW.TITLE");
    const ADD_TITLE = this.translate.instant("VEHICLE.ADD.ADD_TITLE");
    const EDIT_TITLE = this.translate.instant("VEHICLE.ADD.EDIT_TITLE");
    if (!this.id) {
      this.subheaderService.setBreadcrumbs([
        { title: TITLE, page: `/vehicles` },
        { title: ADD_TITLE, page: `/vehicles/add` },
      ]);
      return;
    }
    this.subheaderService.setBreadcrumbs([
      {
        title: TITLE,
        page: `/vehicles`,
      },
      {
        title: `${this.product.make ? this.product.make.name : ""} ${
          this.product.model ? this.product.model.model : ""
        }`,
        page: `/vehicles/details/${this.id}`,
      },
    ]);
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  initProduct() {
    this.createForm();
    this.loadingSubject.next(false);
  }

  createForm() {
    this.productForm = this.productFB.group({
      vehicle_make: [
        {
          value: this.product.vehicle_make,
          disabled: !this.id,
        },
        Validators.required,
      ],
      vehicle_model: [
        {
          value: this.product.vehicle_model,
          disabled: !this.id,
        },
        Validators.required,
      ],
      year: [this.product.year ? moment().year(this.product.year) : ""],
      vin_number: [this.product.vin_number],
      vehicle_type_id: [this.product.vehicle_type_id, Validators.required],
      fuel_type: [this.product.fuel_type, Validators.required],
      vehicle_condition_id: [this.product.vehicle_condition_id],
      new_price_cents: [this.product.new_price_cents],
      first_registration_date: [this.product.first_registration_date],
      fuel_consumption: [this.product.fuel_consumption],
      trim: [this.product.trim],
      mileage_car: [this.product.mileage_car],
      market_car: [this.product.market_car],
      electric_consumption: [this.product.electric_consumption],
      electric_range: [this.product.electric_range],
      co2_per_km: [this.product.co2_per_km],
      curb_weight: [this.product.curb_weight],
      battery_capacity: [this.product.battery_capacity],
      fuel_norm: [this.product.fuel_norm],
      is_used_vat: [this.product.is_used_vat || false],
      hasMileagePhoto: [false],
      horse_power: [this.product.horse_power],
      next_inspection: [this.product.next_inspection],

      // num_airbags: [this.product.num_airbags],
      // num_integrated_child_seats: [this.product.num_integrated_child_seats],
      // num_seat_belt_alarms: [this.product.num_seat_belt_alarms],
      // is_special_usage: [this.product.is_special_usage],
      // is_ncap: [this.product.is_ncap],
      // is_abs: [this.product.is_abs]
    });
    this.productForm.get("vehicle_type_id").valueChanges.subscribe((vehicle_type_id) => {
      if (vehicle_type_id) {
        this.productForm.get("vehicle_make").reset();
        this.productForm.get("vehicle_model").reset();
        this.fetchVehicleTypeMakes();
      }
    });

    this.productForm.get("first_registration_date").valueChanges.subscribe((value) => {
      if (value) {
        this.productForm.get("year").patchValue(moment(value));
      }
    });

    if (this.id) {
      // Fetch models for vehicle make
      this.fetchMakeModels(false);
      this.fetchVehicleTypeMakes();
    }

    const settings$: Observable<IResponse<BaseSettingsModel<IRequiredFieldsSettings>>> =
      this.store.select(selectSettings);
    const settingSubscription = settings$.subscribe((settings) => {
      if (settings) {
        this.formSettings = settings.data;
        useVehicleFieldsSettings(settings, this.productForm, this.entityType);
        this.isMileagePhotoRequired = this.getMileagePhotoSettings();
        if (this.formSettings.is_active && this.isMileagePhotoRequired) {
          this.productForm.get("hasMileagePhoto").setValue(true);
        }
      }
    });
    this.componentSubscriptions.push(settingSubscription);
  }
  fetchVehicleTypeMakes() {
    this.productForm.get("vehicle_make").enable();
    this.filteredMakes = this.productForm.get("vehicle_make").valueChanges.pipe(
      startWith(""),
      switchMap((value) => {
        if (typeof value == "string") {
          if (value == "" && !this.productForm.get("vehicle_make").value) {
            this.productForm.get("vehicle_model").reset();
            this.productForm.get("vehicle_model").disable();
          } else this.productForm.get("vehicle_model").enable();

          return this.productsService.getVehicleMakeList(value, this.productForm.get("vehicle_type_id").value);
        } else return of([]);
      }),
      map((res) => res.data)
    );
    this.cdr.markForCheck();
  }

  fetchMakeModels(withReset?) {
    if (withReset) this.productForm.get("vehicle_model").reset();
    this.productForm.get("vehicle_model").enable();

    const make_id = this.productForm.get("vehicle_make").value ? this.productForm.get("vehicle_make").value.id : null;

    this.filteredModels = this.productForm.get("vehicle_model").valueChanges.pipe(
      startWith(""),
      switchMap((value) => {
        if (typeof value == "string") {
          return this.productsService.getMakeModelsList(value, make_id, this.productForm.get("vehicle_type_id").value);
        } else return of([]);
      }),
      map((res) => res.data)
    );
    this.cdr.markForCheck();
  }

  reset() {
    this.hasFormErrors = false;
    this.productForm.markAsPristine();
    this.productForm.markAsUntouched();
    this.productForm.updateValueAndValidity();
  }

  displayFn(vehicle_make: any): string {
    return vehicle_make && vehicle_make.name ? vehicle_make.name : "";
  }

  modelDisplayFn(vehicle_model: any): string {
    return vehicle_model && vehicle_model.model ? vehicle_model.model : "";
  }

  compareFn(obj1: any, obj2: any): boolean {
    return obj1.code === obj2.code ?? obj1 === obj2;
  }

  validateDropzone(): boolean {
    const dropzoneFiles = this.mileagePhotoComponent?.directiveRef?.dropzone()?.files;
    if (this.isMileagePhotoRequired && (!dropzoneFiles || !dropzoneFiles.length)) {
      return false;
    } else {
      return true;
    }
  }

  onSubmit() {
    this.errorMsgs = {};
    this.hasFormErrors = false;
    const controls = this.productForm.controls;
    this.isMileagePhotoValid = this.validateDropzone();
    /** check form */
    if (this.productForm.invalid || !this.isMileagePhotoValid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
      this.hasFormErrors = true;
      this.cdr.markForCheck();
      this.layoutUtilsService.showActionNotification(this.translate.instant("GENERAL.FORM_REQUIRED_FIELDS"));
      return;
    }

    let product: VehicleModel = this.prepareProduct();
    const formData = new FormData();
    Object.keys(product).forEach((field) => formData.set(field, product[field]));
    if (this.entityType) formData.set("entity_type", this.entityType);

    if (this.id > 0) {
      this.updateProduct(formData);
      return;
    }

    this.addProduct(formData);
  }

  /**
   * Returns object for saving
   */
  prepareProduct(): VehicleModel {
    const controls = { ...this.productForm.controls };

    let product: VehicleModel = new VehicleModel();

    Object.keys(controls).forEach((key) => {
      if (controls[key] && controls[key].value != null && controls[key].value != undefined) {
        product[key] = controls[key].value;
      }
    });

    if (this.id) {
      product["_method"] = "PUT";
    }

    product.make_id = controls["vehicle_make"].value ? controls["vehicle_make"].value.id : null;
    product.model_id = controls["vehicle_model"].value ? controls["vehicle_model"].value.id : null;
    delete product.vehicle_make;
    delete product.vehicle_model;

    if (this.mileagePhotoComponent?.directiveRef?.dropzone()?.files?.length && controls["hasMileagePhoto"].value) {
      product.mileagePhoto = this.mileagePhotoComponent.directiveRef.dropzone().files.slice(-1).pop();
    }

    if (controls["fuel_type"].value != this.FuelTypesEnum.ELECTRIC) {
      delete product.electric_consumption;
      delete product.electric_range;
    }
    if (controls["fuel_type"].value) product.fuel_type = controls["fuel_type"].value;

    product.vehicle_valuation = this.forVehicleValuation ? 1 : 0;

    if (controls["first_registration_date"] && controls["first_registration_date"].value) {
      product.first_registration_date = this.typesUtilsService.formatDate(
        controls["first_registration_date"].value,
        "dd-MM-yyyy"
      );
    }

    if (controls["year"].value) {
      product.year = controls["year"].value.year();
    } else {
      product.year = "";
    }

    product.horse_power = controls["horse_power"]?.value || "";
    return product;
  }

  addProduct(payload) {
    this.loadingSubject.next(true);

    const createProductSubscription = this.productsService.createVehicle(payload).subscribe(
      (res) => {
        this.loadingSubject.next(false);
        if (res.data && this.withSubmit) {
          this.layoutUtilsService.showActionNotification(res.message, MessageType.Create, 10000, true, false);
        }
        this.reset();
        this.vehicleSubmitted.emit(res.data ?? null);
      },
      (err) => {
        this.loadingSubject.next(false);
        if (err.error) {
          this.errorMsgs = err.error.errors || {};
        } else {
          this.errorMsgs = {};
        }
        this.hasFormErrors = true;
        this.cdr.markForCheck();
      }
    );
    this.componentSubscriptions.push(createProductSubscription);
  }

  updateProduct(payload) {
    this.loadingSubject.next(true);

    const updateProductSubscription = this.productsService.updateVehicle(payload, this.id).subscribe(
      (res) => {
        this.loadingSubject.next(false);
        if (res.data && this.withSubmit) {
          this.layoutUtilsService.showActionNotification(res.message, MessageType.Update, 10000, true, false);
        }
        this.reset();
        this.vehicleSubmitted.emit(res.data ?? null);
      },
      (err) => {
        this.loadingSubject.next(false);
        if (err.error) {
          this.errorMsgs = err.error.errors || {};
        } else {
          this.errorMsgs = {};
        }
        this.hasFormErrors = true;
        this.cdr.markForCheck();
      }
    );
    this.componentSubscriptions.push(updateProductSubscription);
  }

  /**
   * Close alert
   *
   * @param $event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  resetForm() {
    this.productForm.reset();
    this.isMileagePhotoRequired = this.getMileagePhotoSettings();
    if (this.formSettings.is_active && this.isMileagePhotoRequired) {
      this.productForm.get("hasMileagePhoto").setValue(true);
    }
  }

  getMileagePhotoSettings() {
    switch (this.entityType) {
      case EntityType.CONTRACT:
        return this.formSettings.value.vehicleFields.mileagePhoto.requiredInContractForm;
      case EntityType.OFFER:
        return this.formSettings.value.vehicleFields.mileagePhoto.requiredInOfferForm;
      default:
        return this.formSettings.value.vehicleFields.mileagePhoto.requiredInDefaultForm;
    }
  }

  onAddedFile(event: any) {
    this.isMileagePhotoValid = this.validateDropzone();
  }

  onRemoveFile(event: any) {
    this.isMileagePhotoValid = this.validateDropzone();
  }

  setForm(asset: VehicleModel) {
    this.productForm.patchValue(asset);
  }

  setImportedVehicle(vehicle: any) {
    const firstRegDate = vehicle.first_registration_date ? moment(vehicle.first_registration_date) : null;
    const nextInspection = vehicle.next_inspection ? moment(vehicle.next_inspection) : null;
    this.productForm.reset();
    this.productForm.patchValue(vehicle);
    this.productForm?.get("vehicle_type_id").setValue(vehicle.type?.id);
    this.productForm?.get("vehicle_model").setValue(vehicle.model);
    this.productForm?.get("vehicle_make").setValue(vehicle.make);
    this.productForm?.get("year").setValue(+vehicle.year);
    this.productForm?.get("mileage_car").setValue(vehicle.latest_mileage);
    this.productForm?.get("first_registration_date").setValue(firstRegDate);
    this.productForm?.get("next_inspection").setValue(nextInspection);
  }
}
