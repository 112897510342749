import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import {
  CreditroCustomerRole,
  CreditroCustomerStatus,
  CreditroCustomerType,
} from "../../pages/contracts/contract-details/creditro-case/creditro-enums";

@Component({
  selector: "kt-creditro-customers-form-array",
  templateUrl: "./creditro-customers-form-array.component.html",
  styleUrls: ["./creditro-customers-form-array.component.scss"],
})
export class CreditroCustomersFormArrayComponent implements OnInit, OnDestroy {
  @Input() customersFormArray: FormArray = this.formBuilder.array([]);
  @Input() initialCustomersData: any[] = [];
  @Input() disableFormArray = false;
  represantativeFormControl: FormControl = this.formBuilder.control(-1, Validators.required);
  subscriptions: Subscription[] = [];
  creditroCustomerStatus = CreditroCustomerStatus;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.subscribeToRepresentativeChange();
    this.initCustomersArray();
  }

  initCustomersArray() {
    this.initialCustomersData.forEach((customer, index) => {
      this.addCustomer(customer.role === CreditroCustomerRole.REAL_OWNER, customer);
      if (customer.type === CreditroCustomerType.REPRESENTATIVE) this.represantativeFormControl.setValue(index);
    });

    if (this.disableFormArray) this.customersFormArray.disable();
  }

  addCustomer(disableName: boolean = false, customer: any | undefined): FormGroup {
    const customerFormGroup = this.formBuilder.group({
      name: [{ value: "", disabled: disableName }, Validators.required],
      email: [null, Validators.email],
      customer_id: [null, Validators.nullValidator],
      type: [CreditroCustomerType.PERSON],
      role: [null],
    });
    this.customersFormArray.push(customerFormGroup);
    if (customer) customerFormGroup.patchValue(customer);
    if (this.customersFormArray.length === 1) this.represantativeFormControl.setValue(0);
    return customerFormGroup;
  }

  removeCustomer(i: number) {
    this.customersFormArray.removeAt(i);
    this.represantativeFormControl.setValue(
      Math.min(this.represantativeFormControl.value, this.customersFormArray.length - 1)
    );
    this.initialCustomersData.splice(i);
  }

  subscribeToRepresentativeChange(): void {
    const subscription = this.represantativeFormControl.valueChanges.subscribe((value: number) => {
      this.updateCustomersRepresentative(value);
    });
    this.subscriptions.push(subscription);
  }

  updateCustomersRepresentative(repIndex: number) {
    this.customersFormArray.controls.forEach((customerFormGroup: FormGroup, index: number) => {
      if (index === repIndex) {
        customerFormGroup.controls.email.setValidators([Validators.required, Validators.email]);
        customerFormGroup.controls.type.setValue(CreditroCustomerType.REPRESENTATIVE);
      } else {
        customerFormGroup.controls.email.setValidators([Validators.email]);
        customerFormGroup.controls.type.setValue(CreditroCustomerType.PERSON);
      }
      customerFormGroup.controls.email.updateValueAndValidity();
      customerFormGroup.controls.type.updateValueAndValidity();
    });
  }

  trackByFn(index: number, customer: any): number {
    return index;
  }

  getCustomerStatus(index: number) {
    return this.initialCustomersData[index]?.status;
  }

  getCustomerRole(index: number) {
    return this.initialCustomersData[index]?.role;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
